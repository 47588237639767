import logo from './logo.svg';
import './App.css';
import JoinWaitlist from './Components/JoinWaitlist';
import Footer from './Components/Footer';

function App() {
  return (
    <div className="App">
     <JoinWaitlist/>
     <Footer/>
     <div className="white-area"></div>

    </div>
  );
}

export default App;
