import React from "react";
import "./styles.scss"

function Footer(){
return(
    <div className="footer">
        <div className="footer-content">
        Under utvikling av:
        <a href="https://globalcaredevelopment.com/" target="_blank">
        <img src="Assets/logo-footer.png" className="footer-image"/>
        </a>
        </div>
    </div>
)
}

export default Footer