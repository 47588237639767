import React from "react";
import "./styles.scss";
import Helmet from "react-helmet";

function JoinWaitlist() {
  return (
    <div className="join-main">
      <div className="join-content">
        <div className="join-logo">
            <div className="white-triangle"></div>
          <img src="Assets/logo.png" alt="Logo" className="logo"/>
          <img src="Assets/logo-text.png" alt="Logo Text" className="logo-text"/>
        </div>

        <div className="email-content">
            <div className="email-instructions">Vær blant de første som får tilgang til Ung Depresjon <br/><b>- meld deg på vårt nyhetsbrev.</b></div>
          <div id="getWaitlistContainer" data-waitlist_id="21248" data-widget_type="WIDGET_2"></div>
        </div>

        <div className="social-media-section">
        <div className="social-media-heading">Følg oss:</div>
          <div className="social-media-row">
            <a href="https://www.instagram.com/ungdepresjon/" target="_blank" rel="noopener noreferrer" className="social-media-icon">
              <img src="Assets/insta-icon.png" alt="Instagram" className="icon"/>
            </a>
            <a href="https://www.tiktok.com/@ungdepresjon" target="_blank" rel="noopener noreferrer" className="social-media-icon">
              <img src="Assets/tiktok-icon.png" alt="TikTok" className="icon"/>
            </a>
            <a href="https://www.facebook.com/profile.php?id=61566338977677" target="_blank" rel="noopener noreferrer" className="social-media-icon">
              <img src="Assets/fb-icon.png" alt="Facebook" className="icon"/>
            </a>
          </div>
          <div className="social-media-row">
            <a href="https://www.linkedin.com/company/globalcaredevelopment" target="_blank" rel="noopener noreferrer" className="social-media-icon-big">
              <img src="Assets/linkedin-icon.png" alt="LinkedIn" className="icon"/>
            </a>
          </div>
        </div>
      </div>

      <Helmet>
        <link rel="stylesheet" type="text/css" href="https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.css"/>
        <script src="https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.js"></script>
      </Helmet>
    </div>
  );
}

export default JoinWaitlist;